/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'msg': {
    width: 29,
    height: 28,
    viewBox: '-1 -1 30 30',
    data: '<path pid="0" d="M22.6 1.57h.001c1.762.698 2.86 2.047 3.524 3.25.33.601.549 1.157.683 1.562a7.628 7.628 0 01.174.602l.007.03.002.006v0h0v.001l.001.007.002.007c.557 2.363.726 4.791.698 6.973h0v.013c.028 2.147-.169 4.578-.727 6.974h0l-.003.013v0h0v0h0l-.002.007-.006.03-.032.125c-.029.111-.075.275-.142.477a9.456 9.456 0 01-.684 1.561c-.663 1.204-1.762 2.553-3.524 3.251l-.003.002c-1.695.687-4.879 1.01-8.092 1.039-3.207-.03-6.397-.38-8.098-1.041-1.761-.7-2.859-2.047-3.522-3.251a9.47 9.47 0 01-.684-1.561 7.66 7.66 0 01-.174-.602l-.007-.03-.001-.006h0v-.001h0l-.002-.009-.002-.009c-.585-2.39-.754-4.817-.726-6.998h0v-.013c-.028-2.148.169-4.578.727-6.975h0l.003-.012h0l.001-.007.007-.03.031-.125c.03-.112.076-.275.143-.477a9.47 9.47 0 01.684-1.561c.663-1.204 1.761-2.552 3.522-3.25C8.08.878 11.27.528 14.477.5c3.207.03 6.396.38 8.123 1.07z" _stroke="#6D6D75"/><path pid="1" d="M20.05 6.889H8.903c-.37 0-.724.15-.985.416a1.437 1.437 0 00-.408 1.006v12.8l2.787-2.844h9.755c.37 0 .724-.15.985-.417.262-.267.408-.628.408-1.006V8.312c0-.377-.146-.739-.408-1.006a1.38 1.38 0 00-.985-.416zm0 9.956h-9.754l-1.394 1.422V8.31h11.149" _fill="#898B9B"/>'
  }
})
